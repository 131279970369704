// NB: Use 'POST' to all url

export const API_GET_SUPPLIER_GROUPS = '/v2/txt/net/data/suppliergroup/list';
export const API_GET_SUPPLIER_GROUP_ROUTES =
  '/v2/txt/net/data/suppliergroup/route/list';
export const API_GET_SUPPLIER_ROUTE = '/v2/txt/net/data/supplierroute';
export const API_GET_SUPPLIER_VEHICLES =
  '/v2/txt/net/data/supplier/vehicle/list';
export const API_GET_COUNTRIES = '/v2/txt/net/data/country/list';
export const API_GET_MARKETING_MANAGERS =
  '/v2/txt/net/data/marketingmanager/list';
export const API_GET_BANKS = '/v2/txt/net/data/bank/list';
export const API_GET_BANK_BRANCHES = '/v2/txt/net/data/bank/branch/list';
export const API_GET_ROUTES = '/v2/txt/net/data/route/list';
export const API_GET_AVAILABLE_VEHICLES =
  '/v2/txt/net/data/vehicle/product/search';
export const API_GET_VEHICLES = '/v2/txt/net/data/vehicle/list';
export const API_GET_DRIVERS = '/v2/txt/net/driver/list/v2';
export const API_SEARCH_NEAREST_DRIVERS = '/v2/txt/net/driver/nearest/search';
export const API_GET_ELIGIBLE_DRIVERS = '/v2/txt/net/driver/list/v2/eligible';
export const API_GET_PAYMENT_ENTITIES = '/v2/txt/net/data/paymententity/list';
export const API_GET_ROUTE_ZONALS = '/v2/txt/net/data/routezonal/search';
export const API_GET_VEHICLE_PLATE_NUMBERS =
  '/v2/txt/net/data/licenseplate/list'; // TODO: need to be change for further update
export const API_GET_IATA_CODE = '/v2/txt/net/data/airport/list';
export const API_GET_SUPPLIER_CITY_POOLS =
  '/v2/txt/net/data/supplier/pool/list';

export const API_SEARCH_DOCUMENTS = '/v2/txt/net/document/search';
export const API_UPSERT_DOCUMENT = '/v2/txt/net/document/upsert';

export const API_SEARCH_VEHICLE_INVENTORY =
  '/v2/txt/net/vehicleinventory/search';
export const API_UPSERT_VEHICLE_INVENTORY =
  '/v2/txt/net/vehicleinventory/upsert';
export const API_UPSERT_BULK_VEHICLE_INVENTORY =
  '/v2/txt/net/vehicleinventory/upsert/bulk';
export const API_UPSERT_VEHICLE_INVENTORY_LIST =
  '/v2/txt/net/vehicleinventory/upsert/list';
export const API_INSERT_PREBUY_VEHICLE_INVENTORY =
  '/v2/txt/net/vehicleinventory/prebuy/insert';
export const API_UPSERT_PREBUY_VEHICLE_INVENTORY =
  '/v2/txt/net/vehicleinventory/prebuy/upsert';

export const API_SEARCH_PREBUY = '/v2/txt/net/data/prebuy/search';
export const API_SEARCH_PREBUY_BY_DATE = '/v2/txt/net/prebuy/get/date';
export const API_GET_PREBUY = '/v2/txt/net/vehicleinventory/prebuy/get';
export const API_GET_PREBUY_BASE_PRICE =
  '/v2/txt/net/data/prebuy/baseprice/get';
export const API_GET_PREBUY_STATUS_RECAP =
  '/v2/txt/net/vehicleinventory/prebuy/get/count/approval';
export const API_UPSERT_PREBUY_APPROVAL = '/v2/txt/net/approval/prebuy/upsert';
export const API_REVERSE_PREBUY = '/v2/txt/net/vehicleinventory/prebuy/reverse';
export const API_UPDATE_PREBUY_STATUS =
  '/v2/txt/net/vehicleinventory/prebuy/status/update';
export const API_EXTEND_PREBUY = '/v2/txt/net/vehicleinventory/prebuy/extend';

export const API_SEARCH_VEHICLE_INVENTORY_PARTNER_CHANGES =
  '/v2/txt/net/vehicleinventory/partnerchanges/search';

export const API_SEARCH_VEHICLE_BOOKING = '/v2/txt/net/booking/search';
export const API_SEARCH_RENTAL_BOOKING = '/v2/txt/net/booking/cr/search';
export const API_SEARCH_BOOKING_V3 = '/v2/txt/net/booking/search/v3';
export const API_GET_BOOKING_DETAIL_ACTION =
  '/v2/txt/net/booking/detail/actions';
export const API_SEARCH_VEHICLE_BOOKING_CUSTOMER_HELP =
  '/v2/txt/net/booking/search/customer/help';
export const API_UPDATE_VEHICLE_BOOKING_CUSTOMER_HELP_DRIVER =
  '/v2/txt/net/booking/detail/rental/detail/update'; // TODO: add url
export const API_SEARCH_VEHICLE_BOOKING_TRIP =
  '/v2/txt/net/booking/search/trip/count';
export const API_GET_VEHICLE_BOOKING_DETAIL = '/v2/txt/net/booking/detail/id';
export const API_GET_VEHICLE_BOOKING_DETAIL_MAIN_INFORMATION =
  '/v2/txt/net/booking/detail/main';
export const API_GET_VEHICLE_BOOKING_DETAIL_OTHER_INFORMATION =
  '/v2/txt/net/booking/detail/other';
export const API_GET_VEHICLE_BOOKING_DETAIL_ADDONS_INFORMATION =
  '/v2/txt/net/vehiclebooking/addondetails';
export const API_GET_VEHICLE_BOOKING_TRIP_INFO =
  '/v2/txt/net/booking/trip/info';
export const API_GET_VEHICLE_BOOKING_DRIVER_LOCATION =
  '/v2/txt/net/booking/driver/location';
export const API_GET_VEHICLE_BOOKING_DETAIL_RENTAL_DETAIL =
  '/v2/txt/net/data/vehiclebookingdetail/rentaldetail';
export const API_UPDATE_VEHICLE_BOOKING_DETAIL_RENTAL_DETAIL =
  '/v2/txt/net/data/vehiclebookingdetail/rentaldetail/upsert';
export const API_UPDATE_VEHICLE_BOOKING_RENTAL_DETAIL =
  '/v2/txt/net/data/vehiclebookingdetail/rentaldetail/update';
export const API_UPDATE_VEHICLE_BOOKING_POST_ISSUANCE_STATUS =
  '/v2/txt/net/vehiclebooking/postissuancestatus/update';
export const API_GET_VEHICLE_BOOKING_VOUCHER_DOWNLOAD_URL =
  '/v2/txt/net/vehiclebooking/voucher';
export const API_GET_VEHICLE_BOOKING_LIST_DOWNLOAD_URL =
  '/v2/txt/net/booking/search/export';
export const API_GET_VEHICLE_BOOKING_LIST_ASYNC_DOWNLOAD_URL =
  '/v2/txt/net/booking/search/export/async';
export const API_GET_RENTAL_VEHICLE_BOOKING_LIST_DOWNLOAD_URL =
  '/v2/txt/net/booking/cr/search/export';
export const API_GET_RENTAL_VEHICLE_BOOKING_LIST_ASYNC_DOWNLOAD_URL =
  '/v2/txt/net/booking/cr/search/export/async';
export const API_RESEND_ISSUANCE_EMAIL =
  '/v2/txt/net/vehiclebooking/resend/issuance/email';
export const API_RESEND_ISSUANCE_SMS =
  '/v2/txt/net/vehiclebooking/resend/issuance/sms';
export const API_RESEND_CANCELLATION_EMAIL =
  '/v2/txt/net/vehiclebooking/resend/cancellation/email';
export const API_RESEND_CANCELLATION_SMS =
  '/v2/txt/net/vehiclebooking/resend/cancellation/sms';

export const API_POST_ISSUANCE_NOTIFY_SUPPLIER =
  '/v2/txt/net/booking/upsert/action/notify';
export const API_POST_ISSUANCE_RESOLVE_NOTIFICATION =
  '/v2/txt/net/booking/upsert/action/resolve';
export const API_POST_ISSUANCE_RESCHEDULE_AIRPORT_TRANSPORT =
  '/v2/txt/net/booking/reschedule/upsert';

export const API_SEARCH_SUPPLIER_GROUP = '/v2/txt/net/suppliergroup/search';
export const API_UPSERT_SUPPLIER_GROUP = '/v2/txt/net/suppliergroup/upsert';
export const API_GET_SUPPLIER_GROUP = '/v2/txt/net/data/suppliergroup';
export const API_UPDATE_SUPPLIER_GROUP_STATUS =
  '/v2/txt/net/data/suppliergroup/update/status';
export const API_GET_SUPPLIER_GROUP_DOCUMENTS =
  '/v2/txt/net/document/rule/search';
export const API_UPSERT_SUPPLIER_GROUP_DOCUMENT =
  '/v2/txt/net/document/rule/upsert';
export const API_GET_SUPPLIER_GROUP_DOCUMENT_RULE =
  '/v2/txt/net/document/ruleinfo/search';
export const API_UPSERT_BULK_SUPPLIER_GROUP_DOCUMENT_RULE =
  '/v2/txt/net/document/rule/upsert/bulk';

export const API_SEARCH_SUPPLIER_ROUTE = '/v2/txt/net/supplierroute/search';
export const API_UPSERT_SUPPLIER = '/v2/txt/net/data/supplier/upsert';
export const API_V3_UPSERT_SUPPLIER = '/v2/txt/net/data/supplier/upsert/v3';
export const API_GET_SUPPLIER = '/v2/txt/net/data/supplier/get';
export const API_V3_GET_SUPPLIER = '/v2/txt/net/data/supplier/get/v3';
export const API_UPDATE_SUPPLIER_PIC_PHONE_NUMBER =
  '/v2/txt/net/data/supplier/picphonenumber/update';
export const API_UPDATE_SUPPLIER_ROUTE_STATUS =
  '/v2/txt/net/supplierroute/update';
export const API_UPDATE_SUPPLIER_ROUTE_INSTANT_BOOKING_CONFIGURATION =
  '/v2/txt/net/supplier/instantbooking/configuration/upsert';
export const API_GET_SUPPLIER_KEY_INFORMATION = ''; // TODO: add url
export const API_UPSERT_SUPPLIER_KEY_INFORMATION = ''; // TODO: add url
export const API_UPDATE_SUPPLIER_ROUTE_OPERATIONAL_CONFIGURATION =
  '/v2/txt/net/supplier/operationalconfiguration/upsert';

export const API_SEARCH_PRODUCT = '/v2/txt/net/product/search';
export const API_UPSERT_PRODUCT = '/v2/txt/net/product/upsert';
export const API_GET_PRODUCT = '/v2/txt/net/data/product';
export const API_UPDATE_PRODUCT_STATUS =
  '/v2/txt/net/data/product/update/status';
export const API_SEARCH_PRODUCT_CONFIGURATION =
  '/v2/txt/net/product/sellingpolicy/search';
export const API_UPSERT_PRODUCT_CONFIGURATION =
  '/v2/txt/net/product/sellingpolicy/upsert';

export const API_SEARCH_DRIVER = '/v2/txt/net/driver/search/v2';
export const API_UPSERT_DRIVER = '/v2/txt/net/driver/upsert/v2';
export const API_GET_DRIVER = '/v2/txt/net/driver/get/v2';
export const API_UPDATE_DRIVER_STATUS = '/v2/txt/net/data/driver/update/status';
export const API_SEARCH_DRIVER_POINT =
  '/v2/txt/net/review/driverpoints/search/v2';

export const API_SEARCH_ROUTE = '/v2/txt/net/route/search';
export const API_GET_ROUTE = '/v2/txt/net/data/route';
export const API_UPSERT_ROUTE = '/v2/txt/net/route/upsert';
export const API_GET_ROUTE_ADDONS = '/v2/txt/net/data/route/season';
export const API_UPSERT_ROUTE_ADDONS_DETAIL =
  '/v2/txt/net/data/route/addon/upsert';
export const API_GET_ROUTE_ADDON_DETAIL = '/v2/txt/net/data/route/addon/detail';
export const API_GET_ROUTE_ADDON_TEMPLATE =
  '/v2/txt/net/data/route/addon/template';

export const API_SEARCH_SEASON = '/v2/txt/net/data/season/search';
export const API_GET_SEASON = '/v2/txt/net/data/season';
export const API_UPSERT_SEASON = '/v2/txt/net/data/season/upsert';

export const API_SEARCH_VEHICLE = '/v2/txt/net/vehicle/search';
export const API_GET_VEHICLE = '/v2/txt/net/data/vehicle';
export const API_UPSERT_VEHICLE = '/v2/txt/net/vehicle/upsert';

export const API_SEARCH_VEHICLE_PLATE_NUMBER =
  '/v2/txt/net/data/licenseplate/search';
export const API_SEARCH_AVAILABE_VEHICLE_PLATE_NUMBER =
  '/v2/txt/net/data/licenseplate/search/available';
export const API_UPSERT_VEHICLE_PLATE_NUMBER =
  '/v2/txt/net/data/licenseplate/upsert';

export const API_CHECK_USER = '/v2/txt/net/data/user/check';
export const API_UPDATE_USER_LANGUAGE = '/v2/txt/net/user/update/language';

export const API_GET_GEO_ID_LANDMARKS = '/v2/txt/net/landmark/search';

export const API_GET_DATA_LABEL = '/v2/txt/net/label';

export const API_SEARCH_USER_JOB_QUEUE =
  '/v2/txt/net/data/user/search/userjobqueue';
export const API_SEARCH_USER = '/v2/txt/net/data/user/search';
export const API_GET_USER = '/v2/txt/net/data/user/get';
/**
 * @deprecated Use API_UPSERT_USER_V2 instead.
 */
export const API_UPSERT_USER = '/v2/txt/net/data/upsert/user';
export const API_UPSERT_USER_V2 = '/v2/txt/net/data/upsert/user/v2';
export const API_UPSERT_USER_SUPPLIER_ACCESS =
  '/v2/txt/net/data/upsert/user/access';
export const API_GET_USER_GROUPS = '/v2/txt/net/data/auth0/group/get';

export const API_CHANGE_PASSWORD = '/v2/txt/net/data/user/password/change';

export const API_SEARCH_LEAD_TIME_CHILDREN =
  '/v2/txt/net/leadtime/search/children';
export const API_SEARCH_LEAD_TIME_CONFIGURATIONS =
  '/v2/txt/net/leadtime/search/data';
export const API_GET_LEAD_TIME = '/v2/txt/net/leadtime/get';
export const API_UPSERT_LEAD_TIME = '/v2/txt/net/leadtime/upsert';
export const API_DELETE_LEAD_TIME = '/v2/txt/net/leadtime/delete';

export const API_SEARCH_TURN_AROUND_TIME_CHILDREN =
  '/v2/txt/net/turnaroundtime/search/children';
export const API_GET_TURN_AROUND_TIME = '/v2/txt/net/turnaroundtime/get';
export const API_UPSERT_TURN_AROUND_TIME = '/v2/txt/net/turnaroundtime/upsert';
export const API_DELETE_TURN_AROUND_TIME = '/v2/txt/net/turnaroundtime/delete';

export const API_GET_PRODUCT_KEY_INFORMATION = '/v2/txt/net/rentalpolicy/get';
export const API_UPSERT_PRODUCT_KEY_INFORMATION =
  '/v2/txt/net/rentalpolicy/upsert';
export const API_DELETE_PRODUCT_KEY_INFORMATION =
  '/v2/txt/net/driverless/rentalpolicy/delete';

export const API_GET_ADDON_INFORMATION = '/v2/txt/net/addonInformation/get';
export const API_UPSERT_ADDON_INFORMATION =
  '/v2/txt/net/addonInformation/upsert';

export const API_SEARCH_RENTAL_POLICY_TYPES =
  '/v2/txt/net/rentalpolicy/type/search';

export const API_SEARCH_ADD_ON = '/v2/txt/net/addon/get';

export const API_GET_PRODUCT_REFUND_RESCHEDULE_POLICY =
  '/v2/txt/net/carrental/refundreschedule/get';
export const API_UPSERT_PRODUCT_REFUND_RESCHEDULE_POLICY =
  '/v2/txt/net/carrental/refundreschedule/upsert';

export const API_SEARCH_INVENTORY_MONITORING =
  '/v2/txt/net/vehicleinventory/monitoring/search';
export const API_GET_INVENTORY_MONITORING_CSV_DOWNLOAD_URL =
  '/v2/txt/net/vehicleinventory/monitoring/export/csv';

export const API_GET_FAILED_PAYMENTS = '';
export const API_UPDATE_FAILED_PAYMENTS = '';

export const API_UPLOAD = '/v2/txt/net/upload';

// AIRPORT TRANSPORT
export const API_V2_UPSERT_SUPPLIER_GROUP =
  '/v2/txt/net/data/suppliergroup/v2/upsert';
export const API_V2_GET_SUPPLIER_GROUP =
  '/v2/txt/net/data/suppliergroup/v2/get';
export const API_V2_UPSERT_SUPPLIER_ROUTE =
  '/v2/txt/net/data/supplierroute/upsert';

export const API_V2_GET_PRODUCT = '/v2/txt/net/product/v2/get';
export const API_V2_UPSERT_PRODUCT = '/v2/txt/net/product/v2/upsert';

export const API_V2_SEARCH_VEHICLE_INVENTORY =
  '/v2/txt/net/vehicleinventory/search/v2';
export const API_V2_UPSERT_VEHICLE_INVENTORY =
  '/v2/txt/net/vehicleinventory/upsert/v2';
export const API_V2_UPSERT_BULK_VEHICLE_INVENTORY =
  '/v2/txt/net/vehicleinventory/upsert/bulk/v2';

export const API_SEARCH_PRODUCT_ADDON = '/v2/txt/net/addon/search';
export const API_UPSERT_PRODUCT_ADDON = '/v2/txt/net/addon/upsert';

export const API_GET_METER_BASED_PRODUCT_RATE =
  '/v2/txt/net/product/rateconfig/get';
export const API_UPSERT_METER_BASED_PRODUCT_RATE =
  '/v2/txt/net/product/rateconfig/upsert';

export const API_V2_SEARCH_VEHICLE_INVENTORY_USAGE =
  '/v2/txt/net/vehicleinventory/search/v2/usage';
export const API_SEARCH_DRIVERLESS_VEHICLE_INVENTORY_USAGE =
  '/v2/txt/net/vehicleinventory/search/driverless/usage';

export const API_V2_GET_VEHICLE_BOOKING_DETAIL_MAIN_INFORMATION =
  '/v2/txt/net/booking/detail/main/v2';

export const API_V2_GET_VEHICLE_BOOKING_DETAIL_EVENT_HISTORY =
  '/v2/txt/net/booking/detail/event-history';

export const API_SEARCH_SUPPLIER_SERVED_ZONE =
  '/v2/txt/net/data/servedzone/search';
export const API_UPSERT_SUPPLIER_SERVED_ZONE =
  '/v2/txt/net/data/servedzone/upsert';
export const API_UPDATE_SUPPLIER_SERVED_ZONE_STATUS =
  '/v2/txt/net/data/servedzone/update/status';

export const API_UPDATE_VEHICLE_INVENTORY_BLOCK_QUANTITY =
  '/v2/txt/net/vehicleinventory/blocking/update';
export const API_UPDATE_DRIVERLESS_VEHICLE_INVENTORY_BLOCK_QUANTITY =
  '/v2/txt/net/vehicleinventory/driverless/blocking/update';

export const API_SEARCH_PRODUCT_SERVED_ZONE =
  '/v2/txt/net/data/product/servedzone/search';
export const API_UPSERT_PRODUCT_SERVED_ZONE =
  '/v2/txt/net/data/product/servedzone/upsert';
export const API_UPDATE_PRODUCT_SERVED_ZONE_STATUS =
  '/v2/txt/net/data/product/servedzone/status';

export const API_SEARCH_SUPPLIER_PICKUP_PROCEDURE =
  '/v2/txt/net/supplier/pickup/procedure/search';
export const API_UPSERT_SUPPLIER_PICKUP_PROCEDURE =
  '/v2/txt/net/supplier/pickup/procedure/upsert';

export const API_SEARCH_SUPPLIER_TERM =
  '/v2/txt/net/supplier/term/condition/search'; // DEPRECATED
export const API_GET_SUPPLIER_TERM = '/v2/txt/net/supplier/term/condition/get';
export const API_UPSERT_SUPPLIER_TERM =
  '/v2/txt/net/supplier/term/condition/upsert';

export const API_SEARCH_SUPPLIER_PARKING_FEE =
  '/v2/txt/net/addon/meterbased/parking/search';
export const API_UPSERT_SUPPLIER_PARKING_FEE =
  '/v2/txt/net/addon/meterbased/parking/upsert';

export const API_SEARCH_FARE = '/v2/txt/net/inventory/rate/search';
export const API_UPSERT_FARE_BASIC_PRICE = '/v2/txt/net/inventory/rate/upsert';
export const API_UPSERT_BULK_FARE_BASIC_PRICE =
  '/v2/txt/net/inventory/rate/upsert/bulk';
export const API_UPSERT_FARE_SURCHARGE = '/v2/txt/net/addon/rate/upsert'; // TODO: need to set url
export const API_INSERT_BULK_FARE_SURCHARGE =
  '/v2/txt/net/addon/rate/insert/bulk'; // TODO: need to set url
export const API_UPSERT_FARE_PROMO = ''; // TODO: need to set url
export const API_GET_SURCHARGE_CONFIGS = '/v2/txt/net/addon/rate/search';
export const API_UPSERT_BULK_FARE_SURCHARGE =
  '/v2/txt/net/addon/rate/upsert/bulk';

export const API_UPSERT_SURCHARGE_METERBASED =
  '/v2/txt/net/addon/meterbased/upsert';
export const API_SEARCH_SURCHARGE_METERBASED =
  '/v2/txt/net/addon/meterbased/list';
export const API_SEARCH_FARE_METER_SURCHARGE =
  '/v2/txt/net/product/rateconfig/surcharge/meterbased/search';
export const API_UPSERT_FARE_METER_PROMO =
  '/v2/txt/net/promo/meterbased/upsert';
export const API_SEARCH_FARE_METER_PROMO =
  '/v2/txt/net/promo/meterbased/search';

export const API_SEARCH_FARE_ZONE_PROMO_TEMPLATE =
  '/v2/txt/net/promo/zonebased/name/search';
export const API_UPSERT_FARE_ZONE_PROMO_BULK =
  '/v2/txt/net/promo/zonebased/upsert/bulk';

export const API_SEARCH_TOLL_FEE_METERBASED =
  '/v2/txt/net/addon/meterbased/toll/search';
export const API_UPSERT_TOLL_FEE_METERBASED =
  '/v2/txt/net/addon/meterbased/toll/upsert';

export const API_GET_AIRPORT_TRANSFER_BOOKING_DETAIL =
  '/v2/txt/net/airporttransfer/booking/detail';
export const API_REDEEM_AIRPORT_TRANSFER_BOOKING =
  '/v2/txt/net/airporttransfer/booking/redeem';

// TODO: need to be removed
export const API_SEARCH_SKU = '';
export const API_UPSERT_SKU = '';
export const API_UPDATE_SKU_STATUS = '';

export const API_SEARCH_BOOKING_REPORT = '/v2/txt/net/booking/search/reporting';
export const API_GET_BOOKING_REPORT_DOWNLOAD_URL =
  '/v2/txt/net/booking/search/reporting/export';
export const API_GET_BOOKING_REPORT_ASYNC_DOWNLOAD_URL =
  '/v2/txt/net/booking/search/reporting/export/async';

export const EXTERNAL_API_GET_DRIVER_REWARD_POINT =
  '/v2/txt/net/review/driverpoints/get/v2';
export const EXTERNAL_API_SEARCH_DRIVER_REWARD_POINT =
  '/v2/txt/net/review/driverpoints/public/search';
export const EXTERNAL_API_SEARCH_DRIVER_ROUTE =
  '/v2/txt/net/review/driverpoints/routes';
export const EXTERNAL_API_DRIVER_UPSERT_FEEDBACK =
  '/v2/txt/net/review/driverpoints/feedback/upsert';

export const API_SEARCH_DRIVER_FEEDBACK =
  '/v2/txt/net/review/driverpoints/feedback/search';

export const API_UPSERT_POST_ISSUANCE =
  '/v2/txt/net/booking/upsert/postIssuance';

export const API_UPSERT_SUPPLIER_USP =
  '/v2/txt/net/supplier/sellingpoint/upsert';
export const API_SEARCH_SUPPLIER_USP =
  '/v2/txt/net/supplier/sellingpoint/search';

export const API_UPSERT_PRODUCT_USP = '/v2/txt/net/usp/product/upsert';
export const API_SEARCH_PRODUCT_USP = '/v2/txt/net/usp/product/search';

export const API_SEARCH_SUPPLIER_CITY_POOL =
  '/v2/txt/net/data/supplier/pool/summary/search';
export const API_UPSERT_SUPPLIER_CITY_POOL =
  '/v2/txt/net/data/supplier/pool/upsert';
export const API_GET_SUPPLIER_CITY_POOL = '/v2/txt/net/data/supplier/pool/get';
export const API_UPDATE_SUPPLIER_CITY_POOL_STATUS =
  '/v2/txt/net/data/supplier/pool/status/update';

export const API_UPSERT_CARPOOL = '/v2/txt/net/data/carpoolp2p/upsert';
export const API_SEARCH_CARPOOL = '/v2/txt/net/data/carpoolp2p/search';
export const API_UPDATE_CARPOOL_STATUS =
  '/v2/txt/net/data/routezonalpoint/status';
export const API_SEARCH_CARPOOL_ADMIN =
  '/v2/txt/net/data/carpoolp2p/carpooladmins';

export const API_SEARCH_DASHBOARD = '/v2/txt/net/route/zonal/resolver/search';

export const API_SEARCH_DRIVERLESS_ALLOTMENT =
  '/v2/txt/net/vehicleinventory/driverless/search';
export const API_UPSERT_DRIVERLESS_ALLOTMENT =
  '/v2/txt/net/vehicleinventory/driverless/upsert';
export const API_UPDATE_DRIVERLESS_ALLOTMENT_STATUS =
  '/v2/txt/net/vehicleinventory/driverless/status/update';
export const API_UPSERT_BULK_DRIVERLESS_ALLOTMENT =
  '/v2/txt/net/vehicleinventory/driverless/upsert/bulk';

export const API_UPSERT_DRIVERLESS_BASIC_PRICE =
  '/v2/txt/net/inventory/driverless/rate/upsert';
export const API_SEARCH_DRIVERLESS_BASIC_PRICE =
  '/v2/txt/net/inventory/driverless/rate/get';
export const API_UPSERT_DRIVERLESS_BASIC_PRICE_SURCHARGE =
  '/v2/txt/net/driverless/rate/surcharge/basic/upsert';
export const API_UPSERT_DRIVERLESS_PICKUP_RETURN_SURCHARGE =
  '/v2/txt/net/driverless/rate/surcharge/pickupreturn/upsert';
export const API_UPSERT_BULK_DRIVERLESS_BASIC_PRICE_SURCHARGE =
  '/v2/txt/net/driverless/rate/surcharge/basic/upsert/bulk';
export const API_SEARCH_DRIVERLESS_SURCHARGE =
  '/v2/txt/net/driverless/rate/surcharge/search';

export const API_SEARCH_PICKUP_LANE = '/v2/txt/net/data/pickuplanep2p/search';
export const API_UPSERT_PICKUP_LANE = '/v2/txt/net/data/pickuplanep2p/upsert';
export const API_GET_TERMINAL = '/v2/txt/net/data/pickuplanep2p/list';

export const API_GET_PICKUP_LANE_PROCEDURE =
  '/v2/txt/net/data/pickuplanep2p/pickupprocedure/get';
export const API_UPSERT_PICKUP_LANE_PROCEDURE =
  '/v2/txt/net/data/pickuplanep2p/pickupprocedure/upsert';

export const API_GET_PRODUCT_PICKUP_DROPOFF_FEE =
  '/v2/txt/net/driverless/rate/pickupdropoff/get';
export const API_UPSERT_PRODUCT_PICKUP_DROPOFF_FEE =
  '/v2/txt/net/driverless/rate/pickupdropoff/upsert';

export const API_GET_PRODUCT_PICKUP_DROPOFF_FEE_SURCHARGE =
  '/v2/txt/net/pickupdropoff/surcharge/get';
export const API_UPSERT_PRODUCT_PICKUP_DROPOFF_FEE_SURCHARGE =
  '/v2/txt/net/pickupdropoff/surcharge/upsert';

export const API_SEARCH_DRIVER_QUEUE = '/v2/txt/net/driverqueue/search';
export const API_GET_DRIVER_QUEUE_SUMMARY =
  '/v2/txt/net/driverqueue/get/summary';
export const API_UPSERT_DRIVER_QUEUE = '/v2/txt/net/driverqueue/upsert'; // TODO: add url

export const API_SEARCH_INSTANT_VEHICLE_BOOKING = ''; // TODO: add url

export const API_GET_SEASON_CITY_DETAIL = '/v2/txt/net/data/route/addon/getV2';
export const API_UPSERT_SEASON_CITY_DETAIL =
  '/v2/txt/net/data/route/addon/upsertV2';

export const API_GET_CURRENCY_LIST = '/v2/txt/net/currency/list';

export const API_UPSERT_DRIVER_ACCOUNT = '/v2/txt/net/data/upsert/driver/user';
export const API_RESERT_DRIVER_ACCOUNT_PASSWORD =
  '/v2/txt/net/driver/user/reset/password';
export const API_REBLAST_DRIVER_PASSWORD =
  '/v2/txt/net/driver/user/reblast/password';
export const API_REGISTER_DRIVER_CHAT = '/v2/txt/net/driver/user/register/chat';
export const API_GET_DRIVER_REPORT = '/v2/txt/net/driver/report';

export const API_UPSERT_PRE_TRAVEL_INFO = '/v2/txt/net/pretravelinfo/upsert';
export const API_SEARCH_PRE_TRAVEL_INFO = '/v2/txt/net/pretravelinfo/search';

// TODO(jeffry-ang): Found that API_SEARCH_ROUTE_MAINTENANCE value equals with API_SEARCH_ROUTE.
export const API_SEARCH_ROUTE_MAINTENANCE = '/v2/txt/net/route/search';
export const API_UPSERT_ROUTE_MAINTENANCE = '/v2/txt/net/route/p2p/upsert';
export const API_SEARCH_ROUTE_ZONAL_MAINTENANCE =
  '/v2/txt/net/route/zonal/search';
export const API_UPSERT_ROUTE_ZONAL_MAINTENANCE =
  '/v2/txt/net/route/zonal/upsert';
export const API_SEARCH_ROUTE_ZONAL_POINT_MAINTENANCE =
  '/v2/txt/net/data/routezonalpoint/search';
export const API_UPSERT_ROUTE_ZONAL_POINT_MAINTENANCE =
  '/v2/txt/net/data/routezonalpoint/upsert';

export const API_SEARCH_DRIVERLESS_RATE_PROMO =
  '/v2/txt/net/promo/driverless/search';
export const API_UPSERT_DRIVERLESS_RATE_PROMO =
  '/v2/txt/net/promo/driverless/upsert';

export const API_GET_BOOKING_COUNT_SUPPLIER =
  '/v2/txt/net/booking/postissuance/status/count';

export const API_UPSERT_DRIVERLESS_OPERATIONAL_HOUR =
  '/v2/txt/net/driverless/operationalhour/upsert';
export const API_SEARCH_DRIVERLESS_OPERATIONAL_HOUR =
  '/v2/txt/net/driverless/operationalhour/search';
export const API_DELETE_DRIVERLESS_OPERATIONAL_HOUR =
  '/v2/txt/net/driverless/operationalhour/delete';

export const API_UPSERT_NOTIFICATION_TOKEN =
  '/v2/txt/net/user/update/notification/token';
export const API_SEARCH_NOTIFICATIONS = '/v2/txt/net/notification/search';
export const API_UPDATE_NOTIFICATIONS = '/v2/txt/net/notification/update';

export const API_SEARCH_SURCHARGE_BY_SEARCH_TIME =
  '/v2/txt/net/addon/searchdeparture/search';
export const API_UPSERT_SURCHARGE_BY_SEARCH_TIME =
  '/v2/txt/net/addon/searchdeparture/upsert';

export const API_UPSERT_AT_SKU_CAMPAIGN =
  '/v2/txt/net/product/at/campaign/upsert';
export const API_GET_AT_SKU_CAMPAIGN = '/v2/txt/net/product/at/campaign/search';

export const API_UPSERT_CR_SKU_CAMPAIGN =
  '/v2/txt/net/product/cr/campaign/upsert';
export const API_GET_CR_SKU_CAMPAIGN = '/v2/txt/net/product/cr/campaign/search';

export const API_SEARCH_AT_PARTNER_CHANGES =
  '/v2/txt/net/inventory/activitylog/p2p/search';
